<script>
  import { loadJS } from './h.js'
  import { conf, route } from './stores.js'
  import { onMount } from 'svelte'

  let analytics
  let dataLayer = []

  onMount(async () => {
    if($conf.useGoogleAnalyticsId && $conf.googleAnalyticsId){
      await loadJS(`https://www.googletagmanager.com/gtag/js?id=${$conf.googleAnalyticsId}`)
      dataLayer = window.dataLayer || []
      gtag('js', new Date());
      gtag('config', $conf.googleAnalyticsId, {anonymize_ip:true});
      analytics=true
    }
  })
  function gtag(){
    //console.log('gtag', arguments)
    dataLayer.push(arguments)
  }

  $: if (analytics && $route) gtag("config", $conf.googleAnalyticsId, {
    page_path: $route, anonymize_ip:true
  })
</script>