<script>
  import { conf } from './stores.js'
  import { trigger } from "./h.js"
  import Input from './G_Input.svelte'
  import I from './G_I.svelte'

  export let values=[]
  export let defaultValues=false
  export let minInputs=0
  export let maxInputs=0

  /* how to use this component (i.e : for user )
  import Input from './G_Input'
  import InputsGroup from './G_InputsGroup'
  <InputsGroup let:i bind:values={user} maxInputs=10>
    <Input type="text" bind:value={user[i].name} >
    <Input type="text" bind:value={user[i].lastname} >
  </InputsGroup>
  */

  $: minInputs=parseInt(minInputs)
  $: maxInputs=parseInt(maxInputs)
  $: if(minInputs && !values.length) values=[defaultValues || {}]

  function del(i){
    if(!values) values=[]
    values.splice(i,1);
    values=values;
  }

  function add(){
    if(!values) values=[]
    values.push({})
    values=values;
  }
</script>

{#if values && values.length}
  <div class="col-12">
    {#each values as item,i (item)}
        <div class="fieldset row mx-1 pt-2 mb-4">
          <div class="col row mx-auto">
            <slot {i}/>
          </div>
          <div class="col-auto">
            <div class="form-label">&nbsp;</div>
            <button class:invisible={values.length<=minInputs} class="btn btn-sm btn-outline-danger " on:click={()=>del(i)}><I trash/></button>            
            <button class:invisible={i<values.length-1 || (maxInputs && values.length>=maxInputs)} class="btn btn-sm btn-outline-success" on:click={()=>add()}><I plus/></button>
          </div>
        </div>
    {/each}
  </div>
{:else}
  <div class="col-12 pt-2 mb-4">
    <button class="btn btn-sm btn-outline-success" on:click={()=>add()}><I plus/></button>
  </div>
{/if}


<style>
  .fieldset{
    border: 1px solid #a6a6a6;
    border-radius:10px;
    background-color: #f8f8f8;
  }
  .invisible{
    visible:none;
  }
</style>
