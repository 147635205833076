<script>
  import { onMount } from "svelte";
  import { loadJS, platform, api, i18n, cdn, dateTimeFormat } from "./h.js";
  import { conf } from './stores.js'

  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'

  export let meetingId
  export let onClose=()=>{}
  export let pseudo=''

  let viewers=0, streams=0, publisher, publisherScreenSharing, receiveScreenSharing, showJoinButton=true, chatHistory='', chatMessage
  let canScreenShare, canRecord, canPublish, canChat, conferenceViewer, autoRecord
  let session, devices, devicesVideo=[], devicesAudio=[], videoSelected, audioSelected, errorMessage, isRecording
  

  $: if(videoSelected) switchVideoDevice(videoSelected)
  $: if(audioSelected) switchAudioDevice(audioSelected)

  $: if(errorMessage) setTimeout(() => {errorMessage=''}, 5000);

  onMount(async () => {
    await loadJS("https://static.opentok.com/v2/js/opentok.min.js")
    await init()
  })

  let meeting
  let chrono=false
  let nearEndAlertTime=$conf.test?0.15:2 //  minutes (0.15 pour test)
  //nearEndAlertTime=100000000000 
  
  let nearEndAlert=false
  let nearEndLeft
  let nearEndAlertTimeout
  let quitMeetingTimeout
  let updateNearEndLeftInterval

  async function init() {
    await initializeSession()
    if(!meeting) {
      quitMeeting()
      return
    }

    let endDate = new Date(meeting.endDate)
    if($conf.test) endDate = Date.now() + 50000 // pour test
    if(endDate > Date.now()) chrono=true


    canPublish        = !meeting.conference || (meeting.conference && meeting.speaker)
    canScreenShare    = !platform("iOS") && !platform("android") && (!meeting.conference || (meeting.conference && meeting.speaker))
    canRecord         = meeting.manualRecord && meeting.conference && meeting.speaker && !meeting.autoRecord
    autoRecord        = meeting.autoRecord
    conferenceViewer  = meeting.conference && !meeting.speaker
    canChat           = meeting.conference

    if(canPublish) devices=await getListOfDevices()
    

    if(conferenceViewer) joinMeeting()

    if(chrono) {
      nearEndAlertTimeout = setTimeout(function(){
        nearEndAlert=true
        nearEndLeft=Math.ceil(nearEndAlertTime*60)
        updateNearEndLeftInterval=setInterval(updateNearEndLeft,1000)
      }, endDate - nearEndAlertTime*60*1000 - Date.now())
  
      quitMeetingTimeout = setTimeout(function() {
        nearEndAlert=false
        quitMeeting()
      }, endDate - Date.now())
    }
  }

  function updateNearEndLeft(){
    if(nearEndLeft==0){
      clearInterval(updateNearEndLeftInterval)
    } else nearEndLeft-=1
  } 

  async function initializeSession() {
    meeting=await api(`/event/digital/join/${meetingId}`, {eventId: $conf.id})
    if(!meeting) return

    session = OT.initSession(meeting.apiKey, meeting.sessionId)

    const options={ insertMode: 'append', width: '100%', height: '100%', fitMode: "contain" }

    // Subscribe to a newly created stream
    session.on('streamCreated', function(event) {
      let parentElementId = event.stream.videoType === 'screen' ? 'screenSharing' : 'webcams'
      let subscriber=session.subscribe(event.stream, parentElementId, options, handleError)
      if(parentElementId==='screenSharing') receiveScreenSharing=true
      streams++
    })

    session.on('connectionCreated',function (event){
      viewers++
    })
    session.on('connectionDestroyed ',function (event){
      viewers--
    })

    session.on('streamDestroyed',function (event){
      if(event.stream.videoType === 'screen') receiveScreenSharing=false
      streams--
    })

    session.on('archiveStarted', function (event) {
      isRecording=true
    })

    session.on('archiveStopped', function () {
      isRecording=false
    })

    session.on('signal:msg', function(event) {
      let className = event.from.connectionId === session.connection.connectionId ? 'chatMe' : 'chatWorld'
      chatHistory+=`<p class="${className}">${event.data}</p>`
    })

    // Create a publisher
    if(!meeting.conference || (meeting.conference && meeting.speaker)) {
      publisher = OT.initPublisher('webcams', options, handleError);
    }
  }

  function joinMeeting(){
    session.connect(meeting.token, function (error) {
      if (error) {handleError(error)}
      else {
        if(publisher) session.publish(publisher, handleError)
        showJoinButton=false
        if(autoRecord && !isRecording) startRecording()
      }
    })
  }

  function quitMeeting(){

    //if(session) session.off()
    if(session) session.disconnect()
    if(publisher) publisher.destroy()
    if(publisherScreenSharing) stopScreenSharing()

    nearEndAlert=false
    clearInterval(updateNearEndLeftInterval)
    clearTimeout(nearEndAlertTimeout)
    clearTimeout(quitMeetingTimeout)
    onClose()
  }

  function stopScreenSharing(){
    publisherScreenSharing.destroy()
    publisherScreenSharing=null
  }

  function shareScreen(){
    OT.checkScreenSharingCapability(function (response) {
      if (!response.supported || response.extensionRegistered === false) {
        // This browser does not support screen sharing.
      } else if (response.extensionInstalled === false) {
        // Prompt to install the extension.
      } else {
        // Screen sharing is available. Publish the screen.
        const container = document.createElement('div')
        publisherScreenSharing = OT.initPublisher(container,
          {videoSource: 'screen'},
          function (error) {
            if (error) handleError(error)
            else {
              session.publish(publisherScreenSharing, function (error) {
                if (error) handleError(error)
              })
            }
          }
        )
      }
    })
  }

  async function startRecording() {
    isRecording=true
    await api(`/event/digital/archive/start/${meeting.id}`)
  }

  async function stopRecording(){
    await api(`/event/digital/archive/stop/${meeting.id}`)
  }

  async function deleteRecording(){
    await api(`/event/digital/archive/delete/${meeting.id}`)
  }

  async function getRecording(recordingId){
    await api(`/event/digital/archive/get/${meeting.id}`)
  }

  function chatSendMessage(){
    session.signal({
      type: 'msg',
      data: pseudo?`${pseudo} > ${chatMessage}`:chatMessage
    }, function(error) {
      chatMessage=''
      if (error) handleError(error)
    })    
  }

  async function getListOfDevices() {
    let devices=[]
    try{await OT.getUserMedia()} catch(e){handleError(e)}
    devices = await new Promise((resolve, reject) => OT.getDevices(function (error, devices) {
      return resolve(devices)
    }))
    devices.forEach(device =>{
      if(device.kind=="videoInput") devicesVideo.push(device)
      if(device.kind=="audioInput") devicesAudio.push(device)
    })
    videoSelected=devicesVideo[0].deviceId
    audioSelected=devicesAudio[0].deviceId
    return devices
  }

  function switchVideoDevice(id){
    if(publisher) publisher.setVideoSource(id).catch(handleError)
  }
  function switchAudioDevice(id){
    if(publisher) publisher.setAudioSource(id).catch(handleError)
  }

  function handleError(error) {
    if (error && error.message) errorMessage=error.message
  }

</script>

<div class="fullScreen">
  <div style="width: 100%; background: url({cdn($conf.banner)}) center top / cover no-repeat; overflow: hidden;">
    <div class="container">
      <div class="row my-1">
        <div class="col-2"> <img class:imgMin={receiveScreenSharing} class="navbarBrandImg" src="{cdn($conf.logo)}" height=60 alt="img" title="img"></div>
        <div class:d-none={receiveScreenSharing} class="ms-auto col slogan display-5">{i18n($conf.name)}</div>
      </div>
    </div>
  </div>
  
  <div class="screens">
    {#if meeting}
    
      {#if meeting.receiver && meeting.attendee}
        <div class="container text-center mt-4 mb-1">
          {meeting.receiver} <I handshake ms me/> {meeting.attendee}
        </div>
        <div class="container text-center mb-4">
          {dateTimeFormat(meeting.startDate, 'time')} - {dateTimeFormat(meeting.endDate, 'time')}
        </div>
        {/if}

      <div class="container text-center my-4">

        {#if errorMessage}
          <div class="alert alert-danger" role="alert">{errorMessage}</div>
        {/if}

        {#if nearEndAlert}
          <div class="alert alert-danger" role="alert"><I18n>digital--This session ends in</I18n> {nearEndLeft} <I18n>seconde(s)</I18n></div>
        {/if}

        {#if canPublish}
          {#if devices}
            {#if showJoinButton}
              <button class="btn btn-danger" on:click={quitMeeting}><I18n>digital--exit</I18n></button>
              <button class="shakeIt btn btn-success" on:click={joinMeeting}><I18n>digital--Join the session</I18n></button>
            {/if}

            {#if !showJoinButton}
              <button class="btn btn-danger" on:click={quitMeeting}><I18n>digital--Leave the session</I18n></button>
              {#if canScreenShare}
                {#if !publisherScreenSharing}
                  <button class="btn btn-primary" on:click={shareScreen}><I18n>digital--Share my screen</I18n></button>
                {:else}
                  <button class="btn btn-danger" on:click={stopScreenSharing}><I18n>digital--Stop sharing</I18n></button>
                {/if}
              {/if}

              {#if canRecord}
                {#if !isRecording}
                  <button class="btn btn-info" on:click={startRecording}><I18n>digital--Record the session</I18n></button>
                {:else}
                  <button class="btn btn-danger" on:click={stopRecording}><I18n>digital--Stop recording</I18n></button>
                {/if}
              {/if}

              <i class="ms-5 fa-light fa-users"></i> {viewers} 
            {/if}

            <div class="d-inline-block">
              <select class="devices form-select" bind:value={videoSelected} aria-label="video selection">
              {#each devicesVideo as device}
                <option value={device.deviceId}>
                  {device.label}
                </option>
              {/each}
            </select>
            <!--
              <select class="devices form-select" bind:value={audioSelected} aria-label="Default select example">
                {#each devicesAudio as device}
                  <option value={device.deviceId}>
                    {device.label}
                  </option>
                {/each}
              </select>
            -->
          </div>


          {:else}
            <div><I18n>Please wait</I18n>...</div>
          {/if}

        {/if}

        {#if conferenceViewer}
          <button class="btn btn-danger" on:click={quitMeeting}><I18n>Quitter la session</I18n></button>
          <i class="ms-5 fa-light fa-users-class"></i> {viewers} 
          {#if !streams}
            <div class="alert alert-info"><I18n>La session n'a pas encore commencé. Veuillez patienter.</I18n></div>
          {/if}
        {/if}

        {#if canChat}
          <div class="input-group mb-3">
            <input bind:value={chatMessage} type="text" class="form-control">
            <button on:click={chatSendMessage} class="btn btn-outline-success" type="button"><i class="fa-light fa-paper-plane"></i></button>
          </div>
          <div>{@html chatHistory}</div>
        {/if}

      </div>

    {/if}

 
    <div id="webcams" 
      class:smallWebcams={receiveScreenSharing} 
      class:bigWebcams={!receiveScreenSharing}>
    </div>
    <div id="screenSharing" class="mt-1"></div>

  </div>
</div>

<style>
  .fullScreen{
    background-color: white;
    z-index:5000;
  }

  .screens{
    height: 80%;
    overflow: auto;
  }

  .imgMin{
    height:20px;
  }

  .bigWebcams{
    display: flex;
    height: 20rem;
  }
  .smallWebcams{
    display: flex;
    height: 4rem;
  }
  #screenSharing{
    height: 40rem;
  }
  :global(.chatMe){
    color:blue;
  }
  :global(.chatWorld){
    color:green;
  }

  :global(.OT_widget-container) {
    background-color: white!important;
  }

  .devices{
    display: inline;
    width: unset;
  }
  
  .slogan{
    font-weight: bold;
    color:var(--colorSlogan);
  }

  .shakeIt {
    animation: shakeIt-effect 6s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes shakeIt-effect {
    0% { transform: translateX(0) }
    1% { transform: translateX(8px) }
    2% { transform: translateX(-8px) }
    3% { transform: translateX(8px) }
    4% { transform: translateX(-8px) }
    5% { transform: translateX(5px) }
    6% { transform: translateX(0) }
    100% { transform: translateX(0) }
  }
</style>