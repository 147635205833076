<script>
  import { conf, lang, userId, user, modal } from './stores.js'
  import { api,setRoute, reloadRoute, fuc, i18n, i18nBr, getById, inArray, toggleInArray, cdn, asArray } from './h.js'
  import _intersection from 'lodash/intersection'
  import { lorem } from './lorem.js'
  import Route from './G_Route.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Stars from './G_Stars.svelte'
  import Spinner from './G_Spinner.svelte'
  import Wrong from './G_Wrong.svelte'
  import Video from './G_Video.svelte'
  import PdfImg from './G_PdfImg.svelte'
  import Agenda from './L_Agenda.svelte'

  export let title=""
  export let icon=false

  export let objs=[], obj={}
  export let id=false

  //export let routeParams=false

  export let participants=false
  export let exhibitors=false
  export let sponsors=false
  export let speakers=false

  export let selection=false
  export let scored=false
  export let trend=false
  export let random=false
  export let discover=false

  export let flat=false
  export let small=false
  export let mini=false

  let cl=$$props.class || ''

  let sizeClass="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
  let sizeHeight=200
  let sizeName="h5"

  let openPdf

  if(small) {
    sizeClass="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3"
    sizeHeight=100
    sizeName="small"
  }
  if(mini) {
    sizeClass="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2"
    sizeHeight=50
    sizeName="mini"
  }

  if(icon===true) icon="circle-arrow-right"

  let ressource
  if(participants) ressource="participants"
  if(exhibitors) ressource="exhibitors"
  if(sponsors) ressource="exhibitors"
  //if(speakers) ressource="participants"
  if(speakers) ressource="speakers"


  let meetMsg=""
  let doMeet=false, doMeetTeamIndex=0, reloadAgenda=1
  function meet(id){
    if($conf.appointments) {
      doMeet=true 
      let elt=document.getElementById("showcaseTop")
      if(elt) elt.scrollIntoView()
    }
    else {
      $modal={open:true, title: i18n("Info"), msg: i18n("events--Appointments are not yet available"), color:'warning', ok:()=>{
      }, cancel:false }
    }
  }
  async function requestMeet(){  
    doMeet=false
    await api(`/event/meetings/request`, { eventId: $conf.id,
      meeting : {
        eventId: $conf.id,
        type:"meeting",
        media:null,
        status:"wait",
        exhibitorId: $user.exhibitorId,
        receiverId: $userId,
        attendeeId:id,
        msg:meetMsg,
        slotId:null,
        startDate:null,
        endDate:null
      }
    })
    $modal={open:true, title: "Info", msg: i18n("events--Request appointment sent"), color:'success', ok:()=>{
    }, cancel:false }
  }

  async function schedule(slot) {
    let ret
    $modal={open:true, title: i18n("events--Make this appointment"), msg: fuc(obj.company), color:'success', confirm:async ()=>{
      ret = await api(`/event/meetings/schedule`, { 
        eventId: $conf.id, 
        meeting : {
          eventId: $conf.id,
          type:"meeting",
          media:null,
          exhibitorId: obj.id,
          receiverId: obj.team[doMeetTeamIndex].id,
          attendeeId:$userId,
          msg:"",
        },
        slot:{
          id:slot.id,
          startDate:slot.startDate,
          endDate:slot.endDate
        }
      })
      if(ret){
        doMeet=false
        doMeetTeamIndex=0
        reloadRoute()
      } else {
        alert(i18n("events--Busy slot"))
        reloadAgenda++
      }
    }, cancel:true }
  }
  
  async function listObjs(){
    let filter="", url
    if(trend) filter="trend"
    if(sponsors) filter="sponsors"
    //if(speakers) filter="speakers"

    url=`/event/${ressource}/list/${filter}`
    //if(scored) url=`/event/${ressource}/scored/list/${$userId}`
    
    if(scored && exhibitors) url=`/event/${ressource}/list/scored/${$userId}`
    if(scored && participants) url=`/event/${ressource}/list/scored/${$user.exhibitorId}`

    if (typeof window !== 'undefined') objs = await api(url, {eventId: $conf.id, request: {valid: true}}) || []

    if(trend) objs=objs.sort(() => .5 - Math.random()).slice(0,3)
    if(random) objs=objs.sort(() => .5 - Math.random())
    if(discover) objs=objs.sort(() => .5 - Math.random()).slice(0,3)
    if(scored) true //objs=??
    if(selection) true //objs=??
  }

  let commonInterests=[]
  async function getObj(id){
    obj = await api(`/event/${ressource}/get/${id}`, {eventId: $conf.id}) || {}
    if($userId){
      if(exhibitors) {
        $user = await api(`/event/participants/get/${$userId}`, {eventId: $conf.id})
        commonInterests=_intersection(obj.interests, $user.interests)
      }
      if(participants) {
        let exhibitor= await api(`/event/exhibitors/get/${$user.exhibitorId}`, {eventId: $conf.id})
        commonInterests=_intersection(obj.interests, exhibitor.interests)
      }
    }
  }

  async function updateFavorite(id){
    $user.favorites=toggleInArray(id, $user.favorites)
    $user=$user
    await api(`/event/participants/set`, {eventId: $conf.id, obj:$user})
  }


  function checkImg(img){
    let emptyImg="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII="
    return img?cdn(img):"https://picsum.photos/300/200?random="+Math.random()//emptyImg
  }

  function doLorem(nb){
    return $conf.test?lorem(nb):" "
  }

</script>

<!-- SHOWCASE OF VISITOR OR EXHIBITOR OR SPONSOR-->
<Route valid={id}>
  {#await getObj(id)}
    <Spinner/>
  {:then}
    <div class="detail">
      <div class="container mb-5">
        <div class="row my-5"><I circle-xmark class="ms-auto I" 4x title="close" onclick={()=>{id=false; doMeet=false}}/></div>

        <!-- EXHIBITOR OR SPONSOR-->
        {#if exhibitors || sponsors}
          <div class="row">
            <div class="col-12 col-lg-4">
              <img src="{checkImg(obj.logo)}" class="w-100 img-fluid" title="obj" alt="obj">

              <div>{#each asArray(obj.subevents) as subevent}<span title={subevent} class="ellipsis badge badge-subevents text-bg-dark me-1">{subevent}</span>{/each}</div>

              {#if $userId && $user.exhibitorId!=obj.id}
                <div class="d-flex justify-content-between mt-2">
                  {#if asArray(obj.team).length}
                    <div class="rounded h2 rippleIt pointer" on:click={()=>meet(obj.id)}><I solid calendar-plus class="I my-2"/><I18n id="events--Meet"/></div>
                  {/if}
                  <div class="h2 pointer" on:click={()=>updateFavorite(obj.id)}><I star solid={inArray(obj.id,$user.favorites)} class="my-2 text-warning"/><I18n id="events--Favorite"/></div>
                </div>
              {/if}
              
              {#if obj.digital}<span class="h6 badge btn-global"><I18n>events--Digital</I18n></span>{/if}
              {#if obj.physical}<!--span class="h6 badge btn-global"><I18n>events--Physical</I18n></span-->{/if}

              {#if obj.website}
                <hr>
                <I link me/><a href={obj.website}>{obj.website}</a>
              {/if}

              {#if obj.team && obj.team.length && $userId}
                <hr>
                {#each obj.team as collaborator}
                  {#if collaborator.valid}
                    <div class="my-3">
                      <I class="I" me user/>{fuc(collaborator.firstname)} {fuc(collaborator.lastname)}
                      - {fuc(collaborator.position)}
                    </div>
                  {/if}
                {/each}
              {/if}

              {#if obj.activities.length}
                <hr>
                <ul>
                  {#each obj.activities as activity}
                    <li class="">{getById($conf.activities,activity).value}</li>
                  {/each}
                </ul>
              {/if}
              {#if commonInterests.length}
                <hr>
                <div class="h5"><I18n id="events--Common interests"/></div>
                {#each commonInterests as interest}
                  <div><I circle-check solid me class="text-warning"/> {getById($conf.interests,interest).value}</div>
                {/each}
              {/if}
            </div>

            <div class="col-12 col-lg-8" id="showcaseTop">
              <p class="display-4">{fuc(obj.name)}</p>
              <p class="h5">{i18n(obj.shortDescription, $lang) || doLorem(5)}</p>

              <!-- MEET AN EXHIBITOR -->
              <Route valid={doMeet} Fade>
                
                <!-- REQUEST MEET EXHIBITOR AS VISITOR -->
                <!-- {#if $user.type!="exhibitor"} -->
                  <hr>
                  <div><I18n id="events--Make an appointment with"/></div>
                
                  <div class="d-flex">
                    {#each obj.team as collaborator, i}
                      {#if collaborator.valid}
                        <div on:click={()=>doMeetTeamIndex=i} class="card pointer team polaroid col-6 col-lg-4 m-1" class:active={doMeetTeamIndex==i}>
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="my-auto">
                                <I class="I" 2x me user/>
                              </div>
                              <div>
                                {fuc(collaborator.firstname)} {fuc(collaborator.lastname)}<br>
                                {fuc(collaborator.position)}
                              </div>
                            </div>
                          </div>
                        </div>
                      {/if}
                    {/each}
                  </div>
            
                  <div class="mt-4"><I18n id="events--Make an appointment detail"/></div>
                  <Agenda reload={reloadAgenda} bothFree notShowMyLocked otherId={obj.team[doMeetTeamIndex].id} action={schedule}/>
                <!-- {/if} -->
                <!-- END REQUEST EXHIBITOR AS VISITOR -->
                

                <!-- REQUEST MEET EXHIBITOR AS EXHIBITOR -->
                <!-- {#if $user.type=="exhibitor"} //must implement email in exhibitor to send wait request
                  <hr>
                  <div><I18n id="events--Request an appointment"/></div>
                  <div><I18n id="events--Request an appointment detail"/></div>
                  <Input bind:value={meetMsg} type="textarea" class="col-lg-12" label="events--Request appointment message" icon="edit"/>
                  <Button validity action={requestMeet} actionModel="send" />
                {/if} -->
                <!-- END REQUEST EXHIBITOR AS EXHIBITOR -->
              
              
              </Route>
              <!-- END MEET AN EXHIBITOR -->


              <p class="">{@html i18nBr(obj.description, $lang) || doLorem(100)}</p>
              {#if obj.videoCdn}
                <div class="polaroid p-1">
                  <Video src={obj.videoCdn}/>
                </div>
              {/if}

              {#if obj.videoLink}
              <div class="polaroid p-1">
                <Video item={{"kind":"link", "link":obj.videoLink}}/>
              </div>
              {/if}

              {#if obj.imagesCdn}
              {#each obj.imagesCdn as image, i}
              <div class="polaroid p-1">
                <img src="{checkImg(image)}" class="w-100 img-fluid" title="obj" alt="obj">
              </div>
              {/each}
              {/if}

              {#if obj.pdfCdn }
                {#if openPdf}
                  <div class="my-5" style="position:relative;">
                    <PdfImg notFull border download close={()=>openPdf=!openPdf} src={obj.pdfCdn}/>
                  </div>
                {:else}
                  <Button icon="book-open-reader" class="my-5" lg action={()=>openPdf=!openPdf} btnGlobal title="exhibitors--Documentation"/>
                {/if}
              {/if}
            </div>
          </div>
      
        {:else}

        <!-- VISITOR -->

          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="text-center">
                <div class="circle btn-global" class:exhibitor={obj.type=="exhibitor"}>
                  <div class="circle-inner lg">
                    <I user 4x/>
                  </div>
                </div>
                {#if obj.type=="exhibitor"}
                  <div class="h5 fw-bold"><I18n id="event--Exhibitor"/></div>
                {/if}
                <div>
                  {#if obj.digital}<span class="h6 badge btn-global"><I18n>events--Digital</I18n></span>{/if}
                  {#if obj.physical}<!--span class="h6 badge btn-global"><I18n>events--Physical</I18n></span-->{/if}
                </div>
                {#if $userId && $userId!=obj.id && (obj.type=="visitor" || obj.type=="exhibitor")}
                  <div class="h2 rounded rippleIt pointer" on:click={()=>meet(obj.id)}><I solid calendar-plus class="I my-2"/><I18n id="events--Meet"/></div>
                  <div class="h2 pointer" on:click={()=>updateFavorite(obj.id)}><I star solid={inArray(obj.id,$user.favorites)} class="my-2 text-warning"/><I18n id="events--Favorite"/></div>
                {/if}
              </div>
              
              
            </div>

            <div class="col-12 col-lg-9">
              <p class="display-4">{fuc(obj.company)}</p>
              <p class="h4">{fuc(obj.firstname)} {fuc(obj.lastname)}</p>
              <p class="h4">{fuc(obj.position)}</p>
              <p class="">{fuc(obj.city)}</p>


              <!-- REQUEST MEET A VISITOR -->
              <Route valid={doMeet} Fade>
                <div><I18n id="events--Request an appointment"/></div>
                <div><I18n id="events--Request an appointment detail"/></div>
                <Input bind:value={meetMsg} type="textarea" class="col-lg-12" label="events--Request appointment message" icon="edit"/>
                <Button validity action={requestMeet} actionModel="send" />
              </Route>
              <!-- END REQUEST MEET A VISITOR -->

              
              {#if obj.activities && obj.activities.length}
                <hr>
                <ul>
                  {#each obj.activities as activity}
                    <li class="">{getById($conf.activities,activity).value}</li>
                  {/each}
                </ul>
              {/if}
              {#if commonInterests.length}
                <hr>
                <div class="h5"><I18n id="events--Common interests"/></div>
                {#each commonInterests as interest}
                  <div><I circle-check solid me class="text-warning"/> {getById($conf.interests,interest).value}</div>
                {/each}
              {/if}
            </div>
          </div>
        {/if}

        <!-- END OF SHOWCASE-->
      </div>
    </div>
  {:catch error}
    <Wrong/>
  {/await}
</Route>

<Route top Fade>
  {#await listObjs()}
    <Spinner/>
  {:then}
    {#if title && objs && objs.length}
      <div class="{cl} container mt-5">
        <div class="tagline">
          {#if icon}<I class="I" solid me {icon}/>{/if}
          {i18n(title, $lang)}
        </div>
        <div class="d-flex flex-wrap" style="z-index:1;">
          {#each objs || [] as obj}

            {#if !$userId || (!(obj && obj.subevents && obj.subevents.length) && !($user && $user.subevents && $user.subevents.length)) || (obj && obj.subevents && $user && $user.subevents && obj.subevents.some(id => $user.subevents.includes(id)))}

              <!--CARDS OF PARTICIPANTS OR SPEAKERS-->
              {#if participants || speakers}
                <div on:click={()=>id=obj.id} class="col-lg-4 col-sm-6 col-12 up pointer mb-4">
                  <div class="card polaroid m-1">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 text-nowrap my-auto">
                          <div class="circle  btn-global" class:exhibitor={obj.type=="exhibitor"}>
                            <div class="circle-inner">{obj.firstname.charAt(0).toUpperCase()}.{obj.lastname.charAt(0).toUpperCase()}</div>
                          </div>
                        </div>
                        <div class="col-9 my-auto">
                          <Stars class="starsOnPeople" stars={obj.stars}/>
                          <div class="h5">{fuc(obj.company || "")}</div>
                          <div class="small">{fuc(obj.position || "")}</div>
                          {#if obj.type=="exhibitor"}
                            <div class="h6 badge rounded-pill text-bg-secondary"><I18n id="event--Exhibitor"/></div>
                          {/if}
                          {#if obj.type=="visitor"}
                          <div class="h6 badge btn-global"><I18n id="event--Visitor"/></div>
                        {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/if}

              <!--CARDS OF SPONSORS (IF FLAT)-->
              {#if flat && sponsors}
                <div on:click={()=>id=obj.id} class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 up pointer mb-4">
                    <img alt="img" title="img" src={checkImg(obj.logo)} style="height: 200px; width: 100%;">
                    <h3>{obj.name}</h3>
                </div>
              {/if}

              <!--CARDS OF EXHIBITORS OR SPONSORS (IF NOT FLAT)-->
              {#if !flat && (exhibitors || sponsors)}
                <div on:click={()=>id=obj.id} class="{sizeClass} up pointer mb-4">
                  <div class="card polaroid p-1 m-1">
                    <div class="aclip card-img-top" 
                      data-background-image={checkImg(obj.logo)}
                      data-loaded="true" 
                      style="height: {sizeHeight}px; width: 100%; background: url({checkImg(obj.logo)}) center center / contain no-repeat; overflow: hidden;">
                    </div>
                    <Stars class="starsOnImg" stars={obj.stars}/>
                    <div class="card-body">
                      <p class="{sizeName} card-title">{fuc(obj.name)}</p>
                      <p class="card-text shortDescription">{i18n(obj.shortDescription, $lang) || doLorem(5)}</p>
                    </div>
                    {#if false && $userId}
                      <div class="card-footer d-flex justify-content-between">
                        <I calendar-plus class="I" onclick={()=>meet(obj.id)}/>
                        <I star solid={inArray(obj.id,$user.favorites)} class="my-2 text-warning"/>
                      </div>
                    {/if}
                  </div>
                </div>
              {/if}

              <!--END OF CARDS-->
            {/if}
          {/each}
        </div>
      </div>
    {/if}
  {:catch error}
    <Wrong/>
  {/await}
</Route>

<style>
  :global(.starsOnImg){
    position: absolute;
    top: 190px;
    right: 10px;
    font-size:0.8rem;
  }
  :global(.starsOnPeople){
    position: absolute;
    top: 5px;
    right: 10px;
    font-size:0.8rem;
  }

  .mini{
    font-size:0.85rem;
    font-weight: bold;
  }
  .small{
    font-size:0.85rem;
    font-weight: bold;
  }

  .shortDescription{
    font-size:0.8rem;
  }

  .badge-subevents{
    background-color: var(--brandColor)!important;
    font-weight: 400;
  }

  .circle {
    display: inline-block;
    margin: 0px;
    border-radius: 50%;
  }

  .circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;
    text-decoration: none;
    height: 50px;
    width: 50px;
    min-width: 50px;  
    font-size: 1rem;
  }

  .circle-inner.lg{
    height: 150px;
    width: 150px;
    min-width: 150px;  
  }

  .circle.exhibitor{
    background-color:grey!important;
  }

  .detail{
    position:fixed;
    background-color:white;
    z-index:10;
    top:80px;
    left:0;
    right:0;
    bottom:50px;
    overflow-y: auto;
    padding-bottom:50px;
  }

  .detail.meet{
    z-index:11;
  }

  .card.team.active{
    border: 2px solid var(--brandColor);
  }

  .shakeIt {
    animation: shakeIt-effect 6s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes shakeIt-effect {
    0% { transform: translateX(0) }
    1% { transform: translateX(8px) }
    2% { transform: translateX(-8px) }
    3% { transform: translateX(8px) }
    4% { transform: translateX(-8px) }
    5% { transform: translateX(5px) }
    6% { transform: translateX(0) }
    100% { transform: translateX(0) }
  }

  .rippleIt {
    animation: ripple 2.5s infinite ease-out;
  }
 
  @keyframes ripple {
    0% {
      /*box-shadow: 0 0 0 0 rgba(200, 200, 200, 0.7), 0 0 0 0 rgba(200, 200, 200, 0.7), 0 0 0 0 rgba(200, 200, 200, 0.7);*/
      box-shadow: 0 0 0 0 var(--brandColor), 0 0 0 0 var(--brandColor), 0 0 0 0 var(--brandColor);
      opacity: 0.7;
    }
    40% {
      box-shadow: 0 0 0 5px rgba(200, 200, 200, 0), 0 0 0 15px rgba(200, 200, 200, 0), 0 0 0 20px rgba(200, 200, 200, 0);
    }
    80% {
      box-shadow: 0 0 0 15px rgba(200, 200, 200, 0), 0 0 0 20px rgba(200, 200, 200, 0), 0 0 0 25px rgba(200, 200, 200, 0);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(200, 200, 200, 0), 0 0 0 25px rgba(200, 200, 200, 0), 0 0 0 30px rgba(200, 200, 200, 0);
    }
  } 
</style>