<script>
  import { conf, lang, route, wsc, user, userId, token, customerId, menus, modal, alert } from './stores.js'
  import { fuc, init, cdn, i18n, i18nBr, api, setCssVars, beep, beepWrong, setRoute, setRouteRoot,  thisYear, dateTimeFormat, inArray, sleep } from './h.js'

  import Offline from './G_Offline.svelte'
  import SpinnerGlobal from './G_SpinnerGlobal.svelte'
  import I from './G_I.svelte'
  import Img from './G_Img.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import Alert from './G_Alert.svelte'
  import Modal from './G_Modal.svelte'
  import Scanner from './G_Scanner.svelte'
  import NavBarMenu from './G_NavBarMenu.svelte'
  import DivButtonMenu from './G_DivButtonMenu.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import GoogleAnalytics from './G_GoogleAnalytics.svelte'
  import Spinner from './G_Spinner.svelte'
  import Wrong from './G_Wrong.svelte'
  import List from './G_List.svelte'
  import FilterLine from './G_FilterLine.svelte'

  import Cards from './L_Cards.svelte'
  import Agenda from './L_Agenda.svelte'
  import Appointements from './L_Appointements.svelte'



  let localConf={
    version:"4.0.0",
    faType:"fa-light",
    logoSize:150
  }
 
  let authId, otp="", exhibitor, participant, listParticipantsKeyword
  let scan={}
  
  let obj=null

  
  //$: console.log($route)
  //$: console.log($conf) //(i) put log in server log also

  $: if(!$user) $user={}

  $: $menus=[
    {route: '/', icon: 'home', valid:!$userId},
    {route: `/workspace/${$user.type}`, name: '<i class="fa-regular fa-home">', valid:$userId},
    {route: '/agenda', name: 'events--Agenda', valid:$userId && $conf.showAgenda && inArray($user.type, ["exhibitor","visitor"])},
    {route: '/workspace/exhibitor/update', name: 'exhibitors--Showcase', valid:$userId && $user.type=="exhibitor"},
    {route: '/leads', name: 'exhibitors--Leads', valid:$userId && $user.type=="exhibitor" && $conf.showLeads},
    {route: '/exhibitors/filtered', name: 'events--Exhibitors', valid:$userId && $user.type!="exhibitor" && $conf.showExhibitors},
    {route: '/exhibitors/all', name: 'events--Exhibitors', valid: (!$userId || ($userId && $user.type=="exhibitor")) && $conf.showExhibitors},
    {route: '/participants', name: 'events--Participants', valid:$userId && $user.type=="exhibitor" && $conf.showParticipants},
    {route: '/speakers', name: 'events--Speakers', valid:$conf.showSpeakers},
    {route: '/workspace/staff', name: 'events--Staff', valid:$userId && $user.type=="staff"},
    {route: '/program', name: 'events--Program', valid:$conf.showProgram},
    {route: '/infos', name: 'events--Infos', valid:$conf.showInfo},
    {route: '/faq', name: 'Faq', valid:$conf.showFaq}
  ]

  init(localConf)
  setCssVars($conf.style)

  $customerId=$conf.customerId //need for api /customer


  $: if($conf.qss.otp && !$userId) {
    $conf.id=$conf.qss.eventId
    $conf=$conf
    authId=otp=$conf.qss.authId
    otp=$conf.qss.otp
    delete $conf.qss.otp
    delete $conf.qss.eventId
    delete $conf.qss.authId
    $conf=$conf
  }
  
  let otpError
  async function sendOtp(){
    otpError=false
    await api(`/event/otp/send/${$conf.id}/${authId}`)
    setRoute("/auth/signin/otp")
  }

  $: if(!$token && $route=="/agenda") setRoute("/auth/signin")

  $: if(!$userId && $token) auth()
  $: if(otp.length==6) signIn()
  async function signIn(){
    otpError=false
    if($userId) {setRoute(); return;} //if we are already auth go to root
    let ret=await api(`/event/signin/${$conf.id}/${authId}/${otp}`)
    otp=""
    if(ret) {
      $token=ret.token
      $userId=ret.id
      $user=ret
      setRoute(`/workspace/${ret.type}`, true)
      $wsc.subscribe('user_' + $userId)
    } else otpError=true
  }

  async function auth(){
    let ret=await api(`/event/auth/${$token}`)
    if(ret) {
      $user=ret
      $userId=ret.id
      if($route=='/') setRoute(`/workspace/${ret.type}`, true)
      $wsc.subscribe('user_' + $userId)
    } else setRoute()
  }

  function signOut(){
    $wsc.unsubscribe('user_' + $userId)
    setRoute("/", true)
    $userId=false
    $token=false
  }


  async function getLeads(filter=""){
    return await api(`/event/leads/list/${$userId}/${filter}`, {eventId: $conf.id})
  }

  function downloadLeads(){
    window.open(`https://api.meetnwin.com/v4/event/leads/list/${$userId}/xlsx?token=${$token}&eventId=${$conf.id}`)
  }


  async function getParticipant(){
    obj={}
    obj = await api(`/event/participants/get/${$userId}`, {eventId: $conf.id}) || {}
  }

  async function updateParticipant(){
    await api(`/event/participants/set`, {eventId: $conf.id, obj:obj})
    $user=obj
    setRoute() 
  }

  async function getExhibitor(){
    obj = await api(`/event/exhibitors/get/${$user.exhibitorId}`, {eventId: $conf.id}) || {team:[]}
  }

  async function updateExhibitor(){
    await api(`/event/exhibitors/set`, {eventId: $conf.id, obj:obj},{spinner:true})
    setRoute()
  }

  async function prepareRegisterExhibitor(){
    obj={team:[{}], billing:{}}
  }

  async function prepareRegisterVisitor(){
    obj={}
  }
  
  async function register(type){
    let ret
    if(type=='exhibitor') ret=await api(`/event/exhibitors/register`, {eventId: $conf.id, obj:obj})
    if(type=='visitor') ret=await api(`/event/participants/register`, {eventId: $conf.id, obj:obj})

    if(ret=="ok") $modal={open:true, title: i18n("events--registration recorded"), msg: i18n($conf.participantRecordedMessage || "events--registration recorded message"), color:'success', ok:()=>{
      obj=null
      setRoute()
    }, cancel:false }

    if(ret=="error") $modal={open:true, title: i18n("error"), msg: i18n("error sorry") + i18n(ret), color:'danger', ok:()=>{
    }, cancel:false }
    if(ret=="email exists") $modal={open:true, title: i18n("error"), msg: i18n("events--this email is already registered"), color:'danger', ok:()=>{
    }, cancel:false }
  }

  async function participantPresent(id){
    await api(`/event/participants/presence/set/${id}`)
    await api(`/event/badge/scan`, {
      eventId: $conf.id,
      userId: $userId,
      ref: id,
      type:"checkpoint",
      checkpointId:"entry"
    })
    $alert={success:"Participant marked as present"}
  }
  
  async function onCodeRead(code){
    scan={status:"WAIT SCAN BADGE", vip:false}
    if(!code) return
    if(!code.ref) {scan.status="INVALID";beepWrong(); return;}
    let ret=await api(`/event/badge/scan`, {
      eventId: $conf.id,
      userId: $userId,
      type: ($user.type=="exhibitor"?"lead":"checkpoint"), 
      checkpointId:($user.type=="exhibitor"?"lead":"entry"),
      ...code
    })
    if(ret && ret.valid) {
      if($user.type=="exhibitor"){
        scan.status="LEAD RECORDED"
      } else {
        scan.status="VALID"
        scan.vip=ret.vip
      }
    }
    else {scan.status="INVALID";beepWrong() }
  }

  function printBadge(id) {
    let popup
    function closePrint () {
      if ( popup ) {
        popup.close();
      }
    }
    //need qrcode route: printing can be only request from the same domain
    console.log($conf.apiUrl)
    popup = window.open(`/api/v4/event/badge/create/${id}`)
    popup.onbeforeunload = closePrint;
    popup.onafterprint = closePrint;
    popup.focus() // Required for IE
    popup.print()
  }

  let programDateTmp
  function programDate(startDate){
    if(programDateTmp==dateTimeFormat(startDate, 'short')) return false
    programDateTmp=dateTimeFormat(startDate, 'short')
    return true
  }

  
  function checkImg(img){
    let emptyImg="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII="
    return img?cdn(img):"https://picsum.photos/300/200?random="+Math.random()//emptyImg
  }
</script>

<Offline/>
<SpinnerGlobal/>
<Modal/>

<div class="global aafullScreen ">

  <!-- menu -->
  <NavBarMenu shortLang={$userId} height=80 sideBreak="xl" signInBtn={$conf.valid && $conf.open} signOutFct={signOut}/>

  <Alert/>

  <!-- main -->
  <main>


    <!-- back button -->
    <Route notRoot noCountMatch Blur>
      <div class="container my-4">
        <button class="btn btn-global" on:click={()=>setRoute()}><I  solid circle-arrow-up-left me/><I18n id="g--Back"/></button>
      </div>
    </Route>

    <Route path="/" valid={$conf.isHomeHtml} top>
      {@html $conf.homeHtml}
    </Route>


    <!-- banner -->
    <Route path="/" valid={!$conf.isHomeHtml} top>
      <div class="old-py-5" 
        style="width: 100%; 
        background: url({$conf.bannerCover && checkImg($conf.banner)}) center center / cover no-repeat; overflow: hidden;">

        {#if !$conf.bannerCover}<img src={checkImg($conf.banner)} width="100%" alt="">{/if}

        <div class="container" >
          <div class="slogan display-1"><I18n id={$conf.name}/></div>
          <div class="subSlogan h3"><I18n id={$conf.shortDescription}/></div>
        </div>
      </div>
      <div class="container my-5">

        <!--div class="tagline"><I class="I" solid bullseye-arrow me/><I18n id="events--The event"/></div-->


        <div>{@html i18nBr($conf.description,$lang)}</div>
      </div>
    </Route>

     <!-- register buttons -->
    <Route path={['/','/register']} valid={
      !($conf.isHomeHtml && $route=="/")
      && !$userId
      && ( ($conf.showRegisterVisitor && $conf.registerVisitor) || ($conf.showRegisterExhibitor && $conf.registerExhibitor) )
      && !$conf.archive
      } top Fade>
      <div class="container my-2">
        <div class="row">
          {#if $conf.showRegisterVisitor && $conf.registerVisitor }
            <div class="col">
              <button class="py-4 w-100 btn btn-lg btn-outline-global mb-1"
                on:click={()=>setRoute('/register/visitor')}>
                Inscription
                <!--I18n id="events--Register visitor"/-->


              </button>
            </div>
          {/if}
          {#if $conf.showRegisterExhibitor && $conf.registerExhibitor}
            <div class="col">
              <button class="py-4 w-100 btn btn-lg btn-outline-global mb-1"
                on:click={()=>setRoute('/register/exhibitor')}><I18n id="events--Register exhibitor"/>
              </button>
          </div>
          {/if}
        </div>
      </div>
    </Route>

    <!-- when & where -->
    <Route path="/" valid={!$conf.isHomeHtml} top>
      <div class="container my-5">
        <div class="tagline display-4"><I class="I" solid calendar me/>
          {#if $conf.freeWhen}
            {i18nBr($conf.freeWhen, $lang)}
          {:else}
            {#if new Date($conf.endDate) - new Date($conf.startDate) >= 1000 * 60 * 60 * 24 }
              <I18n>events--From</I18n> {fuc(dateTimeFormat($conf.startDate, 'short',null,$lang))}
              <I18n>events--To</I18n> {fuc(dateTimeFormat($conf.endDate, 'short',null,$lang))}
            {:else}
              {fuc(dateTimeFormat($conf.startDate, 'short',null,$lang))}
            {/if}
          {/if}
        </div>
        <div class="tagline mt-5 display-4"><I class="I" solid location-dot me/>{@html $conf.address}</div>
      </div>
    </Route>

    <!-- sponsors -->
    <Route path="/" valid={!$conf.isHomeHtml} top>
      <Cards class="mt-5" title="exhibitors--Official sponsors" icon sponsors flat objs={$conf.sponsors}/>
    </Route>


    <!-- signin -->
    <Route path="/auth/signin/*" valid={!$userId} top Fade>
      <div class="container mt-5 d-flex flex-column align-items-center">
        <Img class="mb-4" src={$conf.logo} w={$conf.logoSize} fluid/>

        <Form>
          <Route path="/auth/signin">
            <Input bind:value={authId} autocomplete type="email" label="Email" class="otp" icon required/>
            <Button validity action={sendOtp} actionModel="ok" lg icon="paper-plane" title="g--Receive otp code"/>
          </Route>

          <Route path="/auth/signin/otp">
            <Alert local={{success:"g--otp sent", noDismiss:true}}/>
            {#if otpError}<Alert local={{danger:"g--otp error"}}/>{/if}
            <Input bind:value={otp} icon="key" minlength=6 maxlength=6 type="text" pattern={"[0-9]{6}"} class="otp" label="Code" required/>
            <a route=true href="/auth/signin"><I18n id="g--Resend otp code"/></a>
          </Route>
        </Form>

      </div>
    </Route>


    <!-- Agenda -->
    <Route path="/agenda" top Fade valid={$userId}>
      <div class="container mt-5">
        {#if $user.type=="visitor"}
          <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>events--They wish meet you</I18n></div>
          <Appointements/>
        {/if}

        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>events--My agenda</I18n></div>
        <Agenda myAgenda/>
      </div>
    </Route>


    <!-- Program -->
    <Route path="/program" top Fade>
      <div class="container mt-5">
        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>events--Program</I18n></div>
        {#each $conf.program || [] as p}
          {#if !p.subevent || !$userId || (p.subevent && $user.subevents && $user.subevents.includes(p.subevent))}
            {#if programDate(p.startDate)}
              <div class="h3 mt-4">{fuc(dateTimeFormat(p.startDate, 'short',null,$lang))}</div>
            {/if}
            <div class="h5 mt-4">
              <I me calendar-day/>
              {fuc(dateTimeFormat(p.startDate, 'time',null,$lang))} {i18n(p.name, $lang)}
              {#if p.digital}<span class="h6 badge btn-global"><I18n>events--Digital</I18n></span>{/if}
              {#if p.physical}<!--span class="h6 badge btn-global"><I18n>events--Physical</I18n></span-->{/if}
            </div>
            <p>{@html i18nBr(p.description, $lang)}</p>
          {/if}
        {/each}
        <div class="small text-warning">*<I18n>events--schedules are showed in your timezone</I18n></div>
      </div>
    </Route>

      <!-- Leads -->
      <Route path="/leads" top Fade valid={$userId}>
        <div class="container mt-5">
          <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>exhibitors--My current leads</I18n></div>
            {#await getLeads("wait")}
              <Spinner/>
            {:then leads}
              <List title="g--leads" objs={leads} fields={[
                {id:"date", title:"g--lead date", format:'shortDt'},
                {id:"company", title:"g--company"},
                {id:"firstname", title:"g--firstname"},
                {id:"lastname", title:"g--lastname"},
                {id:"method", title:"g--method"}
              ]}/>
            {:catch}
              <Wrong/>
            {/await}

          <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>exhibitors--My acquired leads</I18n></div>
          {#await getLeads()}
            <Spinner/>
          {:then leads}
            {#if $conf.showExhibitorDownloadLeads }
              <button on:click={downloadLeads} class="btn btn-outline-success"><I18n>exhibitors--Export leads to excel</I18n></button>
            {/if}
            <List title="g--leads" objs={leads} fields={[
              {id:"date", title:"g--lead date", format:'shortDt'},
              {id:"company", title:"g--company"},
              {id:"firstname", title:"g--firstname"},
              {id:"lastname", title:"g--lastname"},
              {id:"method", title:"g--method"}
            ]}/>
          {:catch}
            <Wrong/>
          {/await}

        </div>
      </Route>


    <!-- user space -->
    <Route path="/workspace/*" valid={$userId} top Fade>
      <div class="container mt-5">

         <!-- menu user space -->
        <Route path={["/workspace/visitor","/workspace/exhibitor","/workspace/press","/workspace/speaker"]}>
          <div class="fsContentHorizontal center mb-4">
            <Img src={$conf.logo} w={$conf.logoSize} fluid/>
            <h4 class="ms-4"><I18n>Welcome</I18n> {$user.firstname}</h4>
          </div>


          <DivButtonMenu colorBg={$conf.style.colorText} colorText={$conf.style.colorBg} menus={[
            {route:"/agenda", name:"events--My agenda", icon:"calendar-days", valid: $conf.showAgenda},
            {route:"/leads", name:"exhibitors--My leads", icon:"handshake",valid:$user.referral && $user.type=="exhibitor"},
            {route:"/scan", name:"Scan", icon:"qrcode", valid:$user.type=="exhibitor" && $conf.showScanner},
            {route:"/workspace/exhibitor/update", name:"exhibitors--My showcase", icon:"presentation-screen",valid:$user.referral && $user.type=="exhibitor"},
            {route:"/profil", name:"g--My profil", icon:"user",valid:$userId},
            {route:"/badge", name:"participants--My badge", icon:"id-badge", valid:true},
          ]}/>
        </Route>


        <!-- visitor space -->
        <Route path="/workspace/visitor" valid={$userId}>
          <!-- Espace Visiteur -->
        </Route>


        <!-- exhibitor space -->
        <Route path="/workspace/exhibitor/*" valid={$userId}>
          <!-- exhibitor update -->
          <Route path="/workspace/exhibitor/update">
            {#await getExhibitor() then}
              <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>exhibitors--My showcase</I18n></div>

              <!--Cards exhibitors id={obj.id}/-->

              <Form>
                <Input type="hr" label="exhibitors--presentation" icon="info"/>
                <Input class="col-12" bind:value={obj.name}  label="events--company brand" icon="fa-bullhorn" required/>
                <Input class="col-12" bind:value={obj.shortDescription}  maxlength=150 icon="minimize" type="text" label="exhibitors--Short description" help="exhibitors--Short description help" required multilang translator/>
                <Input class="col-12" bind:value={obj.description} rows=5 icon type="textarea" label="exhibitors--Description" required multilang translator/>
        
                <Input class="col-sm-4" bind:value={obj.logo} maxSize=0.1 legend accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="exhibitors--Logo" icon/>
                <Input class="col-sm-4" bind:value={obj.videoCdn} maxSize=30 legend accept="video/*" type="file" label="exhibitors--Video" icon/>
                <Input class="col-sm-4" bind:value={obj.pdfCdn} maxSize=5 legend accept="application/pdf" type="file" label="exhibitors--Pdf" icon/>
                <Input class="col-12" bind:value={obj.videoLink} icon type="url" label="exhibitors--external video link" help="exhibitors--external video link help"/>
                <Input class="col-12" bind:value={obj.imagesCdn} multiple maxValues="5" icon accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="exhibitors--Images" /> 
                <Input class="col-sm-12" bind:value={obj.website} icon type="url" label="g--Website"/>

                <!--Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.activities} icon="search" type="switch" options={$conf.activities} label="events--activities"/-->
             
                <Input type="hr" label="events--interests" icon="info"/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.interests} icon="search" type="switch" options={$conf.interests} label="events--exhibitor interests"/>

                <Input type="hr" label="exhibitors--Collaborators" icon="users"/>
                <InputsGroup let:i bind:values={obj.team} maxInputs={obj.maxTeam}>
                  <Input type="hidden" required bind:value={obj.team[i].id} />
                  <Input lg set={false} class="col-sm-12" bind:value={obj.team[i].referral} type="switch" option="events--referral contact"/>
                  <Input label="lastname" class="col-sm-6" type="text" icon="name" required bind:value={obj.team[i].lastname}/>
                  <Input label="firstname" class="col-sm-6" type="text" icon="name" required bind:value={obj.team[i].firstname} />
                  <Input label="position" class="col-sm-6" type="text" icon="position" required bind:value={obj.team[i].position} />
                  <Input label="email" class="col-sm-6" type="email" icon required bind:value={obj.team[i].email} />
                  <Input label="phone" class="col-sm-6" type="tel" icon required bind:value={obj.team[i].phone} />
                  <Input label="landline phone" class="col-sm-6" type="tel" icon="phone" bind:value={obj.team[0].landlinePhone}/>

                  {#if $conf.subevents && $conf.subevents.length}
                    <Input inCol="col-12" set={obj.subevents} required customValidity={()=>{return obj.team[i] && obj.team[i].subevents && obj.team[i].subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.team[i].subevents} type="checkbox" options={$conf.subevents} optionsUseValueAsId label="events--subevents" labelBold/>
                  {/if}
                </InputsGroup>
                <Button class="col-auto" action={()=>setRoute()} actionModel="cancel"/>
                <Button class="col-auto" validity action={updateExhibitor} actionModel="update" />
              </Form>
            {/await}
          </Route>
        </Route>


        <!-- press space -->
        <Route path="/workspace/press">
          <!-- Espace press -->
        </Route>


        <!-- speaker space -->
        <Route path="/workspace/speaker">
          <!-- Espace speaker -->
        </Route>


        <!-- staff space -->
        <Route path="/workspace/staff" valid={$userId}>
          <!-- Espace staff -->
          <div class="fsContentHorizontal center mb-4">
            <Img src={$conf.logo} w={$conf.logoSize} fluid/>
            <h4 class="ms-4"><I18n>Welcome</I18n> {$user.firstname}</h4>
          </div>

          <DivButtonMenu colorBg={$conf.style.colorText} colorText={$conf.style.colorBg} menus={[
            {route:"/scan", name:"Scan", icon:"qrcode", valid: inArray($user.staffRoles,['scan', 'welcome'])},
            {route:"/workspace/staff/participants", name:"Participants", icon:"users", valid: inArray($user.staffRoles,['welcome','client'])},
            {route:"/profil", name:"g--My profil", icon:"user",valid:$userId},
            {route:"/badge", name:"participants--My badge", icon:"id-badge", valid:true},
          ]}/>
        </Route>

        <Route path="/workspace/staff/participants">
          <h3><I person-walking me/><I18n>Participants</I18n></h3>
          <FilterLine search bind:keyword={listParticipantsKeyword}/>

          {#if inArray($user.staffRoles,['client']) || (listParticipantsKeyword && listParticipantsKeyword.length>=3)}
            {#await api(`/event/participants/list`, {request:{valid:true}, eventId: $conf.id, keyword: listParticipantsKeyword }) then listParticipants}
              <List let:obj 
                title="g--participants" objs={listParticipants} fields={[
                {id:"lastname", title:"g--lastname"},
                {id:"firstname", title:"g--firstname"},
                {id:"company", title:"g--company"},
                {id:"type", title:"g--type"},
                {id:"vip", title:"participants--Vip"},
                {id:"present", title:"participants--present"}
                ]} action>
                <div slot="action">
                  <button class="btn btn-success" on:click|stopPropagation={()=>participantPresent(obj.id)}>Mark present</button>
                  <button class="btn btn-success" on:click|stopPropagation={()=>printBadge(obj.id)}>Print badge</button>
                </div>
              </List>
            {/await}
          {/if}
        </Route>

      </div>
    </Route>


    <!-- Scan -->
    <Route path="/scan" top  valid={$userId}>
      <div id="scan" class="mt-5">
        <div class="container m-auto" style="max-width:600px;">
          <button role="button" class="btn btn-global mb-2" on:click={beep}><I18n id="scan--Sound test"/></button>
          <div class="h3 py-3 col text-white text-center" 
            class:bg-info={scan.status=="WAIT SCAN BADGE"}
            class:bg-success={scan.status=="VALID" || scan.status=="LEAD RECORDED" }
            class:bg-danger={scan.status=="INVALID"}
            >{i18n(`events--scan-${scan.status}`)}

            {#if scan.vip}
              <div class="mx-auto badge bg-warning">VIP</div>
            {/if}
          </div>
        
          <Scanner {onCodeRead} autoClear/>
        </div>
      </div>
    </Route>


    <!-- Badge -->
    <Route path="/badge" top Fade valid={$userId}>      
      <div class="container mt-5 d-flex flex-column align-items-center">
        <h3>{fuc(`${$user.firstname} ${$user.lastname}`)}</h3>
        <h5>{fuc(i18n(`participants--${$user.type}`))}</h5>
        <Img src={`${$conf.apiUrl}/event/badge/qr/${$userId}`} fluid w=300/>
        <button on:click={()=>printBadge($userId)} role="button" class="btn btn-lg btn-global"><I18n>participants--Print badge</I18n></button>
      </div>
    </Route>


    <!-- Infos -->
    <Route path="/infos" top Fade>
      <div class="container mt-5">
        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>events--Infos</I18n></div>

        <div class="tagline mt-5"><I class="I" solid calendar me/><I18n>events--When</I18n></div>
        <div class="ps-4 h5">
          {#if $conf.freeWhen}
            {i18nBr($conf.freeWhen, $lang)}
          {:else}
            {#if new Date($conf.endDate) - new Date($conf.startDate) >= 1000 * 60 * 60 * 24 }
              <I18n>events--From</I18n> {fuc(dateTimeFormat($conf.startDate, 'short',null,$lang))}
              <I18n>events--To</I18n> {fuc(dateTimeFormat($conf.endDate, 'short',null,$lang))}
            {:else}
              {fuc(dateTimeFormat($conf.startDate, 'short',null,$lang))}
            {/if}
          {/if}
        </div>
        <div class="tagline mt-5"><I class="I" solid location-dot me/><I18n>events--Where</I18n></div>
        <div class="ps-4 h5">{@html $conf.address}</div>

        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>events--Contact</I18n></div>
        <div class="ps-4 h5">{$conf.organizerName || ""}</div>
        {#if $conf.organizerMail}
          <div class="ps-4 h5"><a href={`mailto:${$conf.organizerMail}`}>{$conf.organizerMail}</a></div>
        {/if}
        {#if $conf.organizerPhone}
          <div class="ps-4 h5"><a href={`tel:${$conf.organizerPhone}`}>{$conf.organizerPhone}</a></div>
        {/if}



        {#if $conf.organizerUrl}
          <div class="ps-4 h5"><a href={$conf.organizerUrl}>{$conf.organizerUrl}</a></div>
        {/if}

        
      </div>
    </Route>

    <!-- Faq -->
    <Route path="/faq" top Fade>
      <div class="container mt-5">
        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>g--Faq</I18n></div>
        {#each $conf.faqs || [] as faq}
          <div class="h5 mt-4"><I me square-question/>{i18n(faq.question, $lang)}</div>
          <p>{@html i18nBr(faq.answer, $lang)}</p>
        {/each}
      </div>
    </Route>


    <!-- user profil -->

    <Route path="/profil" top Fade valid={$userId}>
      <div class="container mt-5">
        <div class="tagline"><I class="I" solid me circle-arrow-right/><I18n>g--My profil</I18n></div>
        {#await getParticipant() then}
          <Form>
            <Input type="hr" label="participants--General" icon="info"/>
            <Input bind:value={obj.lastname} class="col-lg-6" label="lastname" icon="user" required/>
            <Input bind:value={obj.firstname} class="col-lg-6" label="firstname" icon="user" required/>
            <Input bind:value={obj.company} class="col-lg-6" label="company" icon required/>


            <!--Input bind:value={obj.position} class="col-lg-6" label="job title" icon required/-->
            {#if $conf.isParticipantFormAS}
              <Input class="col-lg-6" required bind:value={obj.asPosition} type="select" optionsUseValueAsId options={$conf.asPositions} icon="fa-hat-wizard" label="job title"/>

              {#if obj.asPosition=="Autre"}
                <Input bind:value={obj.position} class="col-lg-12" label="Précisez la fonction" icon="fa-hat-wizard" required/>
              {/if}
            {:else}
              <Input bind:value={obj.position} class="col-lg-6" label="job title" icon="fa-hat-wizard" required/>
            {/if}


            <Input bind:value={obj.email} class="col-lg-4" type="email" label="email" icon required/>
            <Input bind:value={obj.phone} class="col-lg-4" type="tel" label="mobile phone" icon required/>
            <Input bind:value={obj.landlinePhone} class="col-lg-4" type="tel" label="landline phone" icon="phone"/>

            <Input bind:value={obj.zip} class="col-lg-3" label="zip" icon/>
            <Input bind:value={obj.city} class="col-lg-3" label="city" icon/>
            <Input bind:value={obj.state} class="col-lg-3" label="state" icon/>
            <Input bind:value={obj.country} class="col-lg-3" type="countries" label="country" icon required/>

            {#if obj.type=="visitor" || obj.type=="exhibitor"}
              {#if $conf.physical &&  $conf.digital}
                <Input lg set={true} class="col-6" bind:value={obj.physical} type="switch" option="yes" label="events--Event physical"/>
                <Input lg set={true} class="col-6" bind:value={obj.digital} type="switch" option="yes" label="events--Event digital"/>
              {/if}
            {/if}

            {#if $conf.subevents.length}
              <Input type="hr" label="Je suis inscris à" icon="info"/>
              <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.subevents && obj.subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.subevents} type="checkbox" options={$conf.subevents} optionsUseValueAsId/>
            {/if}
              <Input empty/>

            {#if obj.type=="visitor"}

              {#if $conf.isParticipantFormAS}
                <Input bind:value={obj.emailContact} class="col-lg-4" type="email" label="E-mail général de l'agence" icon/>
                <Input bind:value={obj.addressPro} class="col-12" label="Adresse postale professionnelle" icon="fa-city"/>

                <Input bind:value={obj.iata} class="col-lg-12" label="events--N° IATA" icon="fa-plane"/>

                <Input bind:value={obj.web} class="col-lg-12" label="Site web de l’agence" icon="fa-link"/>

                <Input type="hr" label="Autres informations" icon="info"/>
                <Input inCol="col-12" required set={"no"} bind:value={obj.alreadyCome} type="radio" options={["no","yes"]} label="Êtes-vous déjà allé(e) en Arabie Saoudite ?"/>
                <Input inCol="col-12" required set={"no"} bind:value={obj.interestedByEductour} type="radio" options={["no","yes"]} label="Seriez-vous intéressé(e) par un eductour en Arabie Saoudite ?"/>
                <Input inCol="col-12" required set={"no"} bind:value={obj.registredOnSaudiExpert} type="radio" options={["no","yes"]} label="Êtes-vous déjà inscrit(e) sur Saudi Expert ?"/>

                <Input inCol="col-12" set={"0"} required bind:value={obj.asNbTravellers} type="radio" options={$conf.asNbTravellers} optionsUseValueAsId label="Nombre de voyageurs déjà envoyés en Arabie Saoudite ?"/>
                <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.asSegmentSold && obj.asSegmentSold.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.asSegmentSold} type="checkbox" optionsUseValueAsId options={$conf.asSegmentSold} label="Vous vendez la destination Arabie Saoudite sur le segment"/>
                <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.asSegmentDev && obj.asSegmentDev.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.asSegmentDev} type="checkbox" optionsUseValueAsId options={$conf.asSegmentDev} label="Vous souhaitez développer l’Arabie sur le segment"/>
              {/if}


              <!--Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.activities} icon="search" type="switch" options={$conf.activities} label="events--activities"/>
                
              <Input bind:value={obj.network} class="col-lg-12" label="events--visitor network" help="events--visitor network help" icon="fa-layer-group"/-->

              {#if $conf.interests}
                <Input type="hr" label="events--interests" icon="info"/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.interests} icon="search" type="switch" options={$conf.interests} label="events--participant interests"/>
              {/if}

              <Input type="hr" label="events--consent" icon="info"/>
              <Input bind:value={obj.consent} class="col-12" type="checkbox" option="events--visitor consent" noLabel required/>
            {/if}

            {#if obj.type!="staff"}
              <Button class="mt-4 col-auto" action={()=>setRoute()} actionModel="cancel"/>
              <Button class="mt-4 col-auto" validity action={()=>updateParticipant()} actionModel="update"/>
            {/if}
          </Form>
        {/await}
      </div>
    </Route>



    <!-- register visitor -->
    
    <Route path="/register/visitor" valid={!$userId} top Fade>
      <div class="container mt-5">
        <div class="text-center mb-5">
          <Img src={$conf.logo} w={$conf.logoSize} fluid/>
        </div>
        {#if $conf.registerVisitor}
          <p>{@html i18nBr($conf.participantFormHead, $lang)}</p>
          {#await prepareRegisterVisitor() then}
            <Form>
              <Input set={true} bind:value={obj.visitor} type="hidden"/>
              
              {#if $conf.subevents.length}
              <Input type="hr" label="Je m'inscris à" icon="info"/>
              <!--Input inCol="col-12" set={[]} bind:value={obj.subevents} type="switch" options={$conf.subevents} optionsUseValueAsId/-->
              <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.subevents && obj.subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.subevents} type="checkbox" options={$conf.subevents} optionsUseValueAsId/>
              {/if}
              
              <Input type="hr" label="participation" icon="info"/>
              {#if $conf.physical && $conf.digital}
                <Input lg set={true} class="col-6" bind:value={obj.physical} type="switch" option="yes" label="events--Event physical"/>
                <Input lg set={true} class="col-6" bind:value={obj.digital} type="switch" option="yes" label="events--Event digital"/>
              {/if}

              <Input bind:value={obj.lastname} class="col-lg-5" label="lastname" icon="user" required/>
              <Input bind:value={obj.firstname} class="col-lg-5" label="firstname" icon="user" required/>
              <Input class="col-lg-2" set={$lang} bind:value={obj.lang} icon="fa-globe" type="select" options={$conf.langs} label="Lang" required/>

              <Input bind:value={obj.company} class="col-lg-6" label={ $conf.participantFormCompanyFieldname || "company"} icon="fa-building" required/>

              {#if $conf.isParticipantFormAS}
                <Input class="col-lg-6" required bind:value={obj.asPosition} type="select" optionsUseValueAsId options={$conf.asPositions} icon="fa-hat-wizard" label="job title"/>

                {#if obj.asPosition=="Autre"}
                  <Input bind:value={obj.position} class="col-lg-12" label="Précisez la fonction" icon="fa-hat-wizard" required/>
                {/if}
              {:else}
                <Input bind:value={obj.position} class="col-lg-6" label="job title" icon="fa-hat-wizard" required/>
              {/if}

              <Input bind:value={obj.email} class="col-lg-4" type="email" label="Votre E-mail" icon required/>
              <Input bind:value={obj.phone} class="col-lg-4" type="tel" label="phone" icon required/>

              {#if !$conf.registerParticipantSimplified}
                <Input bind:value={obj.landlinePhone} type="tel" class="col-lg-4" label="landline phone" icon="phone"/>

                <Input bind:value={obj.zip} class="col-lg-3" label="zip" icon required/>
                <Input bind:value={obj.city} class="col-lg-3" label="city" icon required/>
                <Input bind:value={obj.state} class="col-lg-3" label="state" icon/>
                <Input bind:value={obj.country} class="col-lg-3" type="countries" label="country" icon required/>

                {#if $conf.isParticipantFormAS}
                  <Input bind:value={obj.emailContact} class="col-lg-4" type="email" label="E-mail général de l'agence" icon/>
                  <Input bind:value={obj.addressPro} class="col-12" label="Adresse postale professionnelle" icon="fa-city"/>

                  <Input bind:value={obj.iata} class="col-lg-12" label="events--N° IATA" icon="fa-plane"/>

                  <Input bind:value={obj.web} class="col-lg-12" label="Site web de l’agence" icon="fa-link"/>

                  <Input type="hr" label="Autres informations" icon="info"/>
                  <Input inCol="col-12" required set={"no"} bind:value={obj.alreadyCome} type="radio" options={["no","yes"]} label="Êtes-vous déjà allé(e) en Arabie Saoudite ?"/>
                  <Input inCol="col-12" required set={"no"} bind:value={obj.interestedByEductour} type="radio" options={["no","yes"]} label="Seriez-vous intéressé(e) par un eductour en Arabie Saoudite ?"/>
                  <Input inCol="col-12" required set={"no"} bind:value={obj.registredOnSaudiExpert} type="radio" options={["no","yes"]} label="Êtes-vous déjà inscrit(e) sur Saudi Expert ?"/>

                  <Input inCol="col-12" set={"0"} required bind:value={obj.asNbTravellers} type="radio" options={$conf.asNbTravellers} optionsUseValueAsId label="Nombre de voyageurs déjà envoyés en Arabie Saoudite ?"/>
                  <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.asSegmentSold && obj.asSegmentSold.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.asSegmentSold} type="checkbox" optionsUseValueAsId options={$conf.asSegmentSold} label="Vous vendez la destination Arabie Saoudite sur le segment"/>
                  <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.asSegmentDev && obj.asSegmentDev.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.asSegmentDev} type="checkbox" optionsUseValueAsId options={$conf.asSegmentDev} label="Vous souhaitez développer l’Arabie sur le segment"/>
                {/if}


                {#if $conf.participantFormShowNetwork}
                  <Input bind:value={obj.network} class="col-lg-12" label="events--visitor network" help="events--visitor network help" icon="fa-layer-group"/>
                {/if}
                
                {#if $conf.activities.length}
                  <Input type="hr" label="events--activities" icon="info"/>
                  <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.activities} icon="search" type="switch" options={$conf.activities} label="events--participant activities"/>
                {/if}

                {#if $conf.services.length}
                  <Input type="hr" label="events--services" icon="info"/>
                  <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.services} icon="search" type="switch" options={$conf.services} label="events--participant services"/>
                {/if}

                {#if $conf.projects.length}
                  <Input type="hr" label="events--projects" icon="info"/>
                  <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.projects} icon="search" type="switch" options={$conf.projects} label="events--participant projects"/>
                {/if}

                {#if $conf.interests.length}
                  <Input type="hr" label="events--interests" icon="info"/>
                  <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.interests} icon="search" type="switch" options={$conf.interests} label="events--participant interests"/>
                {/if}
              {/if}
           

              {#if $conf.registerParticipantHasQuestion}
                <Input lg set={false} class="col-12" bind:value={obj.hasQuestion} type="switch" option="yes" label="event--Participant has a question"/>
              {/if}


              <Input type="hr" label="events--consent" icon="info"/>
              <Input bind:value={obj.consent} class="col-12" type="checkbox" option="events--visitor consent" noLabel required/>


              <Input bind:value={obj.consentNewsletter} class="col-12" type="checkbox" option="events--visitor consent newsletter" noLabel/>

              
              <a href={$conf.gdprUrl}><I18n id="gdpr"/></a> 
              
              <Button class="mt-4 col-auto" lg action={()=>setRoute()} actionModel="cancel"/>
              <Button class="mt-4 col" lg validity action={()=>register("visitor")} actionModel="ok" title="events--I register"/>
            </Form>
          {/await}
        {/if}
      </div>
    </Route>



    <!-- register exhibitor -->

    <Route path="/register/exhibitor" valid={!$userId} top Fade>
      <div class="container mt-5">
        <div class="text-center mb-5">
          <Img src={$conf.logo} w={$conf.logoSize} fluid/>
        </div>
        {#if $conf.registerExhibitor}
          <p>{@html i18nBr($conf.exhibitorFormHead, $lang)}</p>
          {#await prepareRegisterExhibitor() then}
            <Form>
              
              {#if $conf.subevents.length}
                <Input type="hr" label="Je souhaite ête exposant à" icon="info"/>
                <Input inCol="col-12" set={[]} required customValidity={()=>{return obj.subevents && obj.subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={obj.subevents} type="checkbox" options={$conf.subevents} optionsUseValueAsId/>
              {/if}

              {#if $conf.physical && $conf.digital}
                <Input type="hr" label="participation" icon="info"/>
                <Input lg set={true} class="col-6" bind:value={obj.physical} type="switch" option="yes" label="events--Event physical"/>
                <Input lg set={true} class="col-6" bind:value={obj.digital} type="switch" option="yes" label="events--Event digital"/>
              {/if}

              {#if $conf.isZones}
                <Input inCol="col-lg-4 col-sm-6 col-12" set="" bind:value={obj.zones} icon="fa-grid-horizontal" type="radio" options={$conf.zones} label="event--zones"/>
              {/if}

              <Input type="hr" label="company" icon="info"/>

              <Input bind:value={obj.name} class="col-lg-6" label="events--company brand" icon="fa-bullhorn" required/>
              <Input bind:value={obj.company} class="col-lg-6" label="events--company name" icon="company" required/>
              <!--Input bind:value={obj.vat} class="col-lg-2" label="VAT number" icon="vat" required/-->
              <Input bind:value={obj.phone} class="col-lg-3" type="tel" label="landline phone" icon="phone" required/>
              <Input bind:value={obj.address} class="col-lg-9" label="address" icon required/>
              <Input bind:value={obj.zip} class="col-lg-3" label="zip" icon required/>
              <Input bind:value={obj.city} class="col-lg-3" label="city" icon required/>
              <Input bind:value={obj.state} class="col-lg-3" label="state" icon/>
              <Input bind:value={obj.country} class="col-lg-3" type="countries" label="country" icon required/>

              <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.activities} icon="search" type="switch" options={$conf.activities} label="events--activities"/>

              <!-- a remettre avec un if eventuellement
              <Input type="hr" label="events--billing address" icon="info"/>
              <Input lg set={false} class="col-12" bind:value={obj.isBillingAdress} type="switch" option="events--use billing address"/>
              {#if obj.isBillingAdress}
                <Input bind:value={obj.billing.address} class="col-lg-12" label="address" icon required/>
                <Input bind:value={obj.billing.zip} class="col-lg-3" label="zip" icon required/>
                <Input bind:value={obj.billing.city} class="col-lg-3" label="city" icon required/>
                <Input bind:value={obj.billing.state} class="col-lg-3" label="state" icon required/>
                <Input bind:value={obj.billing.country} class="col-lg-3" type="countries" label="country" icon required/>
                <Input bind:value={obj.billing.name} class="col-lg-4" label="lastname" icon required/>
                <Input bind:value={obj.billing.email} class="col-lg-4" type="email" label="email" icon required/>
                <Input bind:value={obj.billing.phone} class="col-lg-4" label="phone" icon required/>
              {/if}
              -->

              <Input type="hr" label="events--referral contact" icon="info"/>
              <Input bind:value={obj.team[0].id} type="hidden"/>
              <Input bind:value={obj.team[0].referral} set={true} type="hidden"/>
              <Input bind:value={obj.team[0].lastname} class="col-lg-5" label="lastname" icon="user" required/>
              <Input bind:value={obj.team[0].firstname} class="col-lg-5" label="firstname" icon="user" required/>
              <Input bind:value={obj.team[0].lang} set={$lang} class="col-lg-2" icon="fa-globe" type="select" options={$conf.langs} label="Lang" required/>
              <Input bind:value={obj.team[0].position} class="col-lg-6" label="job title" icon required/>
              <Input bind:value={obj.team[0].email} class="col-lg-6" type="email" label="email" icon required/>
              <Input bind:value={obj.team[0].phone} class="col-lg-6" type="tel" label="mobile phone" icon required/>
              <Input bind:value={obj.team[0].landlinePhone} class="col-lg-6" type="tel" label="landline phone" icon="phone"/>

              {#if $conf.subevents.length}
                <Input inCol="col-12" set={obj.subevents} bind:value={obj.team[0].subevents} type="switch" options={$conf.subevents} optionsUseValueAsId/>
              {/if}
              
              {#if $conf.interests}
                <Input type="hr" label="events--interests" icon="info"/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={obj.interests} icon="search" type="switch" options={$conf.interests} label="events--exhibitor interests"/>
              {/if}

              <Input type="hr" label="events--consent" icon="info"/>
              <Input bind:value={obj.consent} class="col-12" type="checkbox" option="events--exhibitor consent" noLabel required/>
              
              <a href={$conf.gdprUrl}><I18n id="gdpr"/></a> 
              <a href={$conf.cgvUrl}><I18n id="cgv"/></a>
              
              <Button class="mt-4 col-auto" lg action={()=>setRoute()} actionModel="cancel"/>
              <Button class="mt-4 col" lg validity action={()=>register("exhibitor")} actionModel="ok" title="events--I register"/>
            </Form>
          {/await}
        {/if}
      </div>
    </Route>

    

    <!-- Speakers -->
    <Route path="/speakers" top Fade>
      <Cards title="events--Speakers" icon speakers/>
    </Route>


    <!-- participants -->
    <Route path="/participants" top Fly valid={$userId}>      
      <!--Cards title="events--your selection" icon participants selection mini/-->

      <!-- participants scored -->
      <Cards title="events--Selected for you" icon participants scored/>

      <!-- participants all -->
      <Cards title="events--All particpants" icon participants/>
      
    </Route>


    <!-- exhibitors -->
    <Route path="/exhibitors/*" top Fade>


      <!-- exhibitor all -->
      <Route path="/exhibitors/all" top Fade>
       <Cards title="events--Exhibitors" icon exhibitors random/>
      </Route>


      <!-- exhibitor all -->
      <Route path="/exhibitors/filtered" top Fade>
        <!--
        <Cards title="events--your selection" icon exhibitors selection mini/>
        -->

        <Cards title="events--Selected for you" icon exhibitors scored/>

        <Cards title="exhibitors--Top trend" icon exhibitors trend/>

        <Cards title="exhibitors--Sponsors interesting" icon sponsors random/>

        <Cards title="exhibitors--Discover" icon exhibitors discover/>

      

        <!-- all -->
        <Cards title="events--All exhibitors" icon exhibitors small/>
      
        <!-- sponsors -->
        <Cards title="exhibitors--Official sponsors" icon sponsors flat/>

      </Route>

    </Route>

 
    <Route noMatch top Fade>
      <div class="container text-center mt-5">
        <div class="text-center">
          <Img src={$conf.logo} w={$conf.logoSize} fluid/>
        </div>
        <div class="text-center display-5 mt-5">
          <I18n>This page doesn't exist</I18n>
        </div>
        <p class="mt-5">{$route}</p>
      </div>
    </Route>

    <!-- logo organizer -->
    <!--Route path={["/","/register/*"]} noCountMatch>
      <div class="fsContentHorizontal center" style="padding-top:5rem;">
        <h5><I18n id="events--organized by"/> {$conf.organizerName}</h5>
        <Img src={$conf.organizerLogoCdn} w={$conf.logoSize} fluid/>
      </div>
    </Route-->

  </main>


  {#if !($conf.isHomeHtml && $conf.isHomeHtmlHideFooter) || $route!="/"}
  <footer class="fixed-bottom">
    <div class="fsContentHorizontal around py-2">
      <div>
        <i class="fa-solid fa-copyright" aria-hidden="true"></i> {thisYear()} {i18n($conf.name, $lang)}
      </div>

      <div>
        <a href={$conf.gdprUrl || $conf.organizerUrl}><I18n>Rgpd policy</I18n></a>
      </div>

      <div>
        <a href={$conf.cguUrl || $conf.organizerUrl}><I18n>Cgu</I18n></a>
      </div>

      <div>
        <a href={$conf.organizerUrl}>
          <div class="d-none d-lg-inline"><I18n id="events--organized by"/> {$conf.organizerName}</div>
          <Img src={$conf.organizerLogoCdn} h=50 fluid/>
        </a>
      </div>
    </div>
  </footer>
  {/if}

</div>

<GoogleAnalytics/>

<style>
  @font-face {
      font-family: Gilroy;
      font-weight: bold;
      src: url("/event/fonts/Gilroy-ExtraBold.otf") format("opentype");
  }
  @font-face {
      font-family: Gilroy;
      src: url("/event/fonts/Gilroy-Light.otf") format("opentype");
  }

  main{
    padding-bottom:5rem;
  }

 
  .slogan{
    font-weight: bold;
    color:var(--colorSlogan);
  }
  .subSlogan{
    color:var(--colorSlogan);
  }

  :global(.tagline){
    font-size: 2rem;margin-top:1em;
  }

  :global(.otp input){
    text-align:center;
  }

  footer, footer * {
    background-color: var(--navbarBgColor);color:var(--brandColor2);font-size: 0.8rem;
  }
  footer {
    box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.8)!important;
  }

  .global {
    background-color:var(--colorBg)!important;
    color:var(--colorText)!important;
  }

  :global(.I){
    color:var(--brandColor);
  }

  :global(.btn-global) {
    background-color:var(--buttonBgColor)!important;
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  :global(.btn-global:hover) {
    opacity:0.8;
  }
  :global(.btn-outline-global) {
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonBgColor)!important;
  }
  :global(.btn-outline-global:hover) {
    opacity:0.8;
    background-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }

  :global(.link-global) {
    color:var(--colorLink)!important;
  }
</style>