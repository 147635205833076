<script>
  import { conf, userId, user, modal } from './stores.js'
  import { asArray, api, i18n, dateTimeFormat, inArray, toggleInArray, fuc} from "./h.js";

  import Spinner from './G_Spinner.svelte'
  import Wrong from './G_Wrong.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Meeting from './L_Meeting.svelte'

  export let bothFree=false
  export let myAgenda=false

  export let action=false

  export let otherId=""
  export let notShowMyLocked=false

  export let reload=0
 

  
  let showSlotName=false

  async function getAgenda() {
    $user=await api(`/event/participants/get/${$userId}`, { eventId: $conf.id })
    let agenda
    if(myAgenda) agenda=await api(`/event/agenda/${$userId}`, { eventId: $conf.id })
    if(bothFree) agenda=await api(`/event/agenda/${$userId}/${otherId}`, { eventId: $conf.id, notShowMyLocked:notShowMyLocked })
    return agenda
  }


  function slotsDay(epochOrISO){
    return dateTimeFormat(epochOrISO, format, tz='UTC', lang)
  }

  function slotTime(slot, withEnd){
    return dateTimeFormat(slot.startDate, 'time') + (withEnd?' - ' + dateTimeFormat(slot.endDate, 'time'):'')
  }

  async function toggleLock(slot,whichLocks){
    if(whichLocks=="locks") $user.locks=toggleInArray(slot.id, $user.locks)
    if(whichLocks=="locksDigital" && slot.physical && !inArray(slot.id,$user.locksPhysical)) $user.locksDigital=toggleInArray(slot.id, $user.locksDigital)
    if(whichLocks=="locksPhysical" && slot.digital && !inArray(slot.id,$user.locksDigital)) $user.locksPhysical=toggleInArray(slot.id, $user.locksPhysical) 
    $user=$user
    await api(`/event/participants/set`, {eventId: $conf.id, obj:$user})
  }

  async function cancelMeeting(slot){
    $modal={open:true, title: i18n("events--Cancel this appointment"), msg: fuc(slot.company), color:'warning', confirm:async ()=>{
      await api(`/event/meetings/cancel/${slot.meetingId}/${$userId}`, {eventId: $conf.id})
      reload++
    }, cancel:true }
  }

  let onAirMeetingId
  async function joinMeeting(slot){
    onAirMeetingId=slot.meetingId
  }
  async function quitMeeting(){
    onAirMeetingId=false
  }


</script>


{#if onAirMeetingId}
  <Meeting meetingId={onAirMeetingId} onClose={quitMeeting} pseudo={`${$user.firstname} - ${$user.lastname}`}/>            
{/if}


<div class="text-end">
  {#if $user.type=="exhibitor"}
    <div class="row justify-content-end">
      <div class="col-3 text-center">
        <div><I18n>Legend</I18n></div>
        <div class="slotBox"><I18n id="events--Free slot"/></div>
        <div class="slotBox lock"><I me lock/><I18n id="events--Locked slot"/></div>
        <div class="slotBox busy"><I18n id="events--Busy slot"/></div>
      </div>
    </div>
  {/if}
  {#if $conf.physical &&  $conf.digital && $user.physical &&  $user.digital}
    <span class="me-3"><I me handshake/><I18n id="events--Physical"/></span>
    <span class="me-3"><I me laptop/><I18n id="events--Digital"/></span>
  {/if}
</div>

{#await getAgenda(otherId,reload)}
  <Spinner/>
{:then days}
  <div class="row">
    {#key $user}
      {#each asArray(days) as day}
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 ">
          <!-- day -->
          <div class="mt-5 h3 ucfirst">{dateTimeFormat(day.dayDate,'short')}</div>
          {#each asArray(day.slots) as slot}
            {#if true}
              <div class:busy={slot.busy} class="slotBox my-3 p-2" class:lock={inArray(slot.id,$user.locks)} class:pointer={action} class:hover={action} on:click={()=>{if(bothFree) action(slot)}}>
                
                <!-- head slot -->
                <div class="row">

                  <!-- lock -->
                  <div class="col">
                    {#if !slot.busy && !bothFree && $user.type=="exhibitor"}
                      <div><I class="pointer" onclick={()=>toggleLock(slot,"locks")} icon={inArray(slot.id,$user.locks)?"lock":"lock-open"}/></div>
                    {/if}
                  </div>

                  <!-- time -->
                  <div class="col">{slotTime(slot)}</div>

                  <!-- media -->
                  <div class="col">
                    <!-- toggle in my agenda -->
                    {#if !slot.busy && !bothFree && !inArray(slot.id,$user.locks) && $user.type=="exhibitor"}
                      {#if $conf.physical &&  $conf.digital}
                        {#if slot.physical && $user.physical}
                          <I class="pointer" onclick={()=>toggleLock(slot,"locksPhysical")} me icon={!inArray(slot.id,$user.locksPhysical)?"handshake":"handshake-slash"}/>
                        {/if}
                        {#if slot.digital && $user.digital}
                          <I class="pointer" onclick={()=>toggleLock(slot,"locksDigital")} me icon={!inArray(slot.id,$user.locksDigital)?"laptop":"laptop-slash"}/>
                        {/if}
                      {/if}
                    {/if}

                    <!-- bothfree show media-->
                    {#if !slot.busy && bothFree}
                      {#if $conf.physical && $conf.digital}
                        {#if slot.physical}
                          <I handshake/>
                        {/if}
                        {#if slot.digital}
                          <I laptop/>
                        {/if}
                      {/if}
                    {/if}

                  </div>


                </div>

                <!-- busy slot -->
                {#if slot.busy && slot.company}
                  <div>
                    <I class="pointer" onclick={()=>cancelMeeting(slot)} me times/>
                    {slot.company}

                    {#if slot.media=="digital"}
                      {#if $conf.test || (new Date(slot.startDate) < Date.now() && new Date(slot.endDate) > Date.now())}
                        <I class="pointer" onclick={()=>joinMeeting(slot)} solid circle-play beat/>    
                      {:else}
                        <I class="circle-play" thin circle-play/>    
                      {/if}
                    {/if}
                    {#if slot.media=="physical" && slot.standRef} - {slot.standRef}{/if}
                    {#if $conf.physical &&  $conf.digital}
                      {#if slot.media=="digital"}<I laptop/>{/if}
                      {#if slot.media=="physical"}<I handshake/>{/if}
                    {/if}  
                    {#if slot.person}<div class="info">{slot.person}</div>{/if}
                  </div>
                {/if}
              </div>
            {/if}
          {/each}
        </div>
      {/each}
    {/key}
  </div>

{:catch error}
  <Wrong/>
{/await}



<style>
.slotBox {
  border-radius: 4px;
  text-align: center;
  background-color: #ccecfa;
  font-weight: 700;
}
.slotBox.lock{
  background-color: #efefef;
}
.slotBox.busy{
  background-color: #caffca;
}
.slotBox .info{
  font-weight: normal;
  font-size:0.8rem;
}

.slotMinus {
  text-align: center;
  font-weight: 700;
}
.slotBox.hover:hover {
  background-color: #ffe500;
}
</style>