<script>
  import I from './G_I.svelte'
  export let stars=false
  export let icon="star"

  icon="circle"

  let cl=$$props.class || ''
</script>
{#if stars!==false}
  <div class="{cl} stars text-nowrap">
    {#each Array(5) as _, i}
      <I {icon} solid class={i<stars?"fill":""}/>
    {/each}
  </div>
{/if}
<style>
  :global(.stars .fill){
    color:orange;
  }
</style>

