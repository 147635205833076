<script>
  import { conf, userId, user, modal } from './stores.js'
  import { asArray, api, fuc, reloadRoute, i18n } from "./h.js";

  import Spinner from './G_Spinner.svelte'
  import Route from './G_Route.svelte'
  import Wrong from './G_Wrong.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Agenda from './L_Agenda.svelte'

  async function getWaitMeetings() {
    return await api(`/event/meetings/wait/${$userId}`, { eventId: $conf.id })
  }

  let reloadAgenda=0
  let doMeet=false
  async function meet(meeting) {
    doMeet=meeting
  }

  async function reject(meeting) {
    $modal={open:true, title: i18n("events--Appointment reject"), msg: fuc(meeting.receiver.company), color:'warning', confirm:async ()=>{
      await api(`/event/meetings/reject/${meeting.id}`, { eventId: $conf.id })
      reloadRoute()
    }, cancel:true }
  }
  async function schedule(slot) {
    let ret
    $modal={open:true, title: i18n("events--Make this appointment"), msg: fuc(doMeet.receiver.company), color:'success', confirm:async ()=>{
      ret = await api(`/event/meetings/schedule`, { eventId: $conf.id, meetingId:doMeet.id, slot:slot })
      if(ret) {
        reloadRoute()
      } else {
        alert(i18n("events--Busy slot"))
        reloadAgenda++
      }
    }, cancel:true }
  }
  


</script>

  <!-- PENDING APPOINTEMENTS REQUEST -->
  <Route valid={!doMeet}>
    {#await getWaitMeetings()}
      <Spinner/>
    {:then meetings}
      <div class="row">
        {#each asArray(meetings) as meeting}
          <div class="col-lg-4 col-sm-6 col-12 up mb-4">
            <div class="card polaroid m-1">
              <div class="card-body">
                <div class="d-flex">
                  <div class="text-nowrap my-auto me-4">
                    <div class="circle  btn-global" class:exhibitor={meeting.receiver.type=="exhibitor"}>
                      <div class="circle-inner"><I user/></div>
                    </div>
                  </div>
                  <div class=" my-auto">
                    <div class="h5">{fuc(meeting.receiver.company )}</div>
                    <div>{fuc(meeting.receiver.firstname)} {fuc(meeting.receiver.lastname)}</div>
                    <div class="small">{fuc(meeting.receiver.position)}</div>
                  </div>
                  <div class="h4 ms-auto my-auto">
                    <I calendar-plus class="I" onclick={()=>meet(meeting)}/>
                    <I times class="I" onclick={()=>reject(meeting)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/each}
        {#if !asArray(meetings).length}<div><I18n id="events--No appointment request pending your response"/></div>{/if}
      </div>
    {:catch error}
      <Wrong/>
    {/await}
  </Route>


  <!-- CHOOSE APPOINTEMENT DATE -->
  <Route valid={doMeet}>
    {#await doMeet}
      <Spinner/>
    {:then}
      <div class="detail">
        <div class="container mb-5">
          <div class="row my-5"><I circle-xmark class="ms-auto I" 4x title="close" onclick={()=>doMeet=false}/></div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="h5">{fuc(doMeet.receiver.company )}</div>
              <div>{fuc(doMeet.receiver.firstname)} {fuc(doMeet.receiver.lastname)}</div>
              <div class="small">{fuc(doMeet.receiver.position)}</div>
            </div>
            <div class="col-12 col-lg-8">
              <Agenda reload={reloadAgenda} bothFree otherId={doMeet.receiverId} action={schedule}/>
            </div>
          </div>
        </div>
      </div>
    {/await}
  </Route>


<style>
  .circle {
    display: inline-block;
    margin: 0px;
    border-radius: 50%;
  }

  .circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;
    text-decoration: none;
    height: 50px;
    width: 50px;
    min-width: 50px;  
    font-size: 1rem;
  }

  .circle-inner.lg{
    height: 150px;
    width: 150px;
    min-width: 150px;  
  }

  .circle.exhibitor{
    background-color:grey!important;
  }


  .detail{
    position:fixed;
    background-color:white;
    z-index:10;
    top:80px;
    left:0;
    right:0;
    bottom:50px;
    overflow-y: auto;
    padding-bottom:50px;
  }

</style>